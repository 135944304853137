import { Component, HostBinding, Input, Output } from '@angular/core';
import { ContentBlock } from '@tytapp/content/content-block';
import { Subject } from 'rxjs';

interface ContentBlockSlot {
    block: ContentBlock;
    index: number;
    loaded: boolean;
}

@Component({
    selector: 'tyt-content-view',
    templateUrl: './content-view.component.html',
    styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent {
    private _blocks: ContentBlock[] = [];

    @Input() @HostBinding('attr.data-mode') mode: 'standard' | 'split' | 'sidebar-left';
    @HostBinding('class.two-column') get singleColumn() { return this.secondaryBlocks.length > 0; }
    @Input() get blocks() { return this._blocks; }
    set blocks(value) {
        this._blocks = value;
        this.computeDeferred();
    }
    @Output() viewLoaded = new Subject<void>();
    @HostBinding('class.visible') viewIsLoaded = false;

    primaryBlocks: ContentBlockSlot[] = [];
    secondaryBlocks: ContentBlockSlot[] = [];
    @HostBinding('attr.data-blocks-loading') loadCounter = 0;
    private computeTimeout;

    /**
     * Compute everything ahead of time as performantly as possible.
     * PERFORMANCE-SENSITIVE
     */
    private computeDeferred() {
        clearTimeout(this.computeTimeout);
        this.computeTimeout = setTimeout(() => {
            this.primaryBlocks = [];
            this.secondaryBlocks = [];
            this.loadCounter = 0;

            if (this._blocks) {
                this.loadCounter = this._blocks.length;
                for (let index = 0, max = this._blocks.length; index < max; ++index) {
                    const block = this.blocks[index];
                    if (block.secondary) {
                        this.secondaryBlocks.push({ block, index, loaded: false });
                    } else {
                        this.primaryBlocks.push({ block, index, loaded: false });
                    }
                }
            }
        });
    }

    viewDidLoad(slot: ContentBlockSlot) {
        slot.loaded = true;
        this.loadCounter -= 1;
        if (this.loadCounter === 0) {
            this.viewLoaded.next();
            setTimeout(() => this.viewIsLoaded = true, 10);
        }
    }
}
